@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Work Sans", sans-serif;
}

.grecaptcha-badge {
  visibility: hidden;
}

.progress-bar-audio {
  --bar-bg: #9ca3af;
  --seek-before-width: 0;
  --seek-before-color: #e5e7eb;
  --seek-before-height: 3px;
  --knobby: #e5e7eb;
  --selectedKnobby: #d1d5db;
  appearance: none;
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 5px;
  outline: none;
}

.progress-bar-audio::-webkit-slider-runnable-track {
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 3px;
  outline: none;
}

.progress-bar-audio::-moz-range-track {
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 3px;
  outline: none;
}

.progress-bar-audio::-moz-focus-outer {
  border: 0;
}

.progress-bar-audio::before {
  content: '';
  height: 5px;
  width: var(--seek-before-width);
  background-color: var(--seek-before-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
}

.progress-bar-audio::-moz-range-progress {
  background-color: var(--seek-before-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 3px;
}

.progress-bar-audio::-webkit-slider-thumb {
  -webkit-appearance: none;
  transform: scale(1.35);
  height: 11px;
  width: 11px;
  border-radius: 50%;
  border: none;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  margin: -4px 0 0 0;
  z-index: 3;
  box-sizing: border-box;
}

.progress-bar-audio:active::-webkit-slider-thumb {
  transform: scale(1.4);
  background: var(--selectedKnobby);
}

.progress-bar-audio:hover {
  --seek-before-color: #0284c7;
  --seek-before-height: 5px;
}

.progress-bar-audio::-moz-range-thumb {
  transform: scale(1.35);
  height: 11px;
  width: 11px;
  border-radius: 50%;
  border: transparent;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  z-index: 3;
}

.progress-bar-audio:active::-moz-range-thumb {
  transform: scale(1.4);
  background: var(--selectedKnobby);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #b3b3b3;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
